import React from 'react';

// Sarah's additions
import PDFViewer from "../App/components/PDFViewer";
import PDFJSBackend from '../backends/pdfjs';
// end Sarah

//import { Link, graphql } from "gatsby";
import { Link } from "gatsby";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faFile, faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import OnSendEmailVerificationButton from '../components/Session/onSendEmailVerification';
import Layout from '../App/components/layout';
import FavoriteForm from '../components/Favorite';
//import PDFviewerComponent from '../App/components/pdfViewComponent';
import * as ROUTES from '../constants/routes';

import { AuthUserContext } from '../components/Session';


import SEO from "../App/components/seo"


const Blog = (props) => {
    var p = props.pageContext.slug;
    const pathname = p.replace('-', '_');
    const title = props.pageContext.title;
    const uri = props.pageContext.uri;
    const pdf = props.pageContext.pdf;
    //const ocr = props.pageContext.pdf.ocrdDoc; not currently being used and causing issues
    const language = props.pageContext.language;
    const type = props.pageContext.type;
    const manufacturer = props.pageContext.manufacturer;
    //const fileURL = props.pageContext.fileURL;
    const url = process.env.GATSBY_PDF_BASEURL;
    const fileURL = url+props.pageContext.pdf.ocrdDoc;
    return(

        <Layout>
            <SEO title="CECA-ACEA MCP" />
            <AuthUserContext.Consumer>
              {authUser =>
                authUser ? (
                    authUser.emailVerified ? (

                  <div className="wrapper">
                  <article>
                    <Link to={ROUTES.INDEX} className="back-btn"><FontAwesomeIcon className="back" icon={faArrowLeft} />Return to Manuals</Link>

                    <div className="whiteBox">
                        <h1>{title}</h1>
                        <ul>
                            <li>Manufacturer: {manufacturer} </li>
                            <li>Elevator Type: {type}</li>
                            <li>Language: {language}</li>
                        </ul>
                    </div>
                    {pdf ? (
                        <div className="whiteBox">
                            <div id="filtering">
                                {/*<form>
                                <div className="form-group password-group">
                                    <label for="search">Search Manual</label>
                                    <div className="relative" id="rel1">
                                        <input
                                            name='search'
                                            value={title}
                                            id="search"
                                            className="fullInput"
                                            type='text'
                                        />
                                        <div class="position-end">
                                            <label for="submitSearch" className="pointer"><span className="screen-reader">Search</span><FontAwesomeIcon className="search" icon={faSearch} /></label>
                                            <button className="visually-hidden" type="submit" name="submitSearch" id="submitSearch" />
                                        </div>
                                    </div>
                                </div>
                                </form>
                                */}
                            </div>
                            {/*
                            <object data={props.data.craft.entry.manual[0].url + "#toolbar=0"} type="application/pdf" width="500" height="500">
                                <embed src={props.data.craft.entry.manual[0].url + "#toolbar=0"} type="application/pdf"/>
                            </object>
                            */}
                            {/*<PDFviewerComponent url={ pdf.url } ocr={ocr} />*/}
                            <PDFViewer
                                backend={PDFJSBackend}
                                src={pdf.url}
                             />

                            <footer>
                                <span><FontAwesomeIcon className="file fave" icon={faFile} /> PDF</span><span><FontAwesomeIcon className="file fave" icon={faInfoCircle} /> Pages</span><span>Favorite <FavoriteForm className="file nonfave" language={language} type={type} manufacturer={manufacturer} pathname={pathname} slug={p} title={title} uri={uri} fileURL={fileURL} checkMe={authUser.favorites.hasOwnProperty(pathname) ? true : false } /></span>
                            </footer>
                        </div>
                    ): (
                        <>PDF coming soon</>
                    )}


                </article>
                </div>
                ) : (
                    <OnSendEmailVerificationButton/>
                    )
              ) : (
                <div className="wrapper"> You need to <Link to="/signin">log in</Link> to see restricted
                 content</div>
              )
            }
          </AuthUserContext.Consumer>
        </Layout>
    )
}

export default Blog;

/*
            <object data={props.data.craft.entry.manual[0].url + "#toolbar=0"} type="application/pdf" width="500" height="500">
                <embed src={props.data.craft.entry.manual[0].url + "#toolbar=0"} type="application/pdf"/>
            </object>

*/
