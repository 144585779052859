export default class PDFJs {
  init = (source, element) => {

    const iframe = document.createElement('iframe');

    //iframe.src = `/pdfs/web/index.html?file=${source}`;
    iframe.src = `${source}`;

    //var url = `${source}`;
    /*
    var loadingTask = pdfjs.getDocument({
        url: url,
        httpHeaders: { 'Access-Control-Allow-Origin': '*' }
    });
    */

    iframe.width = '100%';
    iframe.height = '100vh';


    //element.appendChild(iframe);



    const textNode = document.createElement('p');
    textNode.innerHTML = `Our PDF source will be: <a href="${source}" target="_blank">${source}</a>`;

    element.appendChild(textNode);

  }
}
