import React from 'react';


export default class PDFViewer extends React.Component {
  // constructor(props) {
  //   super(props);
  //   //this.viewerRef = React.createRef();
  //   //this.backend = new props.backend();
  // }

  componentDidMount() {

    const { src } = this.props;
    //const element = this.viewerRef.current;

    //this.backend.init(src, element);



    //var url = 'https://info.ceca.dev/ocrs/ocr-17d2814fe6cffa116ddc56cd9ac46c01.pdf';
    var url = src;
    //var script = `http://localhost:8000/pdfs/build/pdf.js`;
    // import('pdfjs-dist/build/pdf.js').then(PDFJS => {

    //   console.log(url);
    //     //
    //       // The workerSrc property shall be specified.
    //       //
    //       PDFJS.GlobalWorkerOptions.workerSrc =
    //         '/pdfs/build/pdf.worker.js';
    //       //
    //       // Asynchronous download PDF
    //       //
    //       var loadingTask = PDFJS.getDocument({
    //           url:  url
    //       });
    //       const iframe = document.createElement('iframe');


    //       loadingTask.promise.then(function(pdf) {
    //           console.log('PDF loaded');
    //           console.log(pdf);
    //           var viewer = document.getElementById('pdf-viewer');


    //           iframe.src = '/pdfs/web/index.html?file='+url;
    //           iframe.width = '100%';
    //           iframe.height = '100vh';
    //           viewer.appendChild(iframe);




    //     });

    // });
}
  render() {
    var url = this.props.src;
    return (
        <>  
          <iframe style={{width: "100%", height:"100vh"}} src={url}></iframe>
        </>
    )
  }
}
